const baseColors = {
  primary: '#0c2949',
  secondary: '#cfd5db',
  red: '#9d1b2a',
  primaryBg: '#fff',

  // datatable
  bgDatatable: '#fff',
  colorDatatable: 'null',

  // select
  bgSelect: '#fff',
  bgMultiValueSelect: '#9d1b2a',
  hoverBgMultiValueRemoveSelect: '#9d1b2a',
  hoverTextMultiValueRemoveSelect: '#f2e6dd',

  // text points product
  colorTextPointsProducts: '#0c2949',

  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#282828'
};

export default baseColors;
